.a-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;

    &-12 {
        height: rem(12);
        width: rem(12);
    }

    &-16 {
        height: rem(16);
        width: rem(16);
    }

    &-18 {
        height: rem(18);
        width: rem(18);
    }

    &-20 {
        height: rem(20);
        width: rem(20);
    }

    &-24 {
        height: rem(24);
        width: rem(24);
    }

    &-28 {
        height: rem(28);
        width: rem(28);
    }

    &-32 {
        height: rem(32);
        width: rem(32);
    }

    &-36 {
        height: rem(36);
        width: rem(36);
    }

    &-48 {
        height: rem(48);
        width: rem(48);
    }

    &-50 {
        height: rem(50);
        width: rem(50);
    }

    &-loading {
        background-image: url('~assets/images/loading.gif');
    }

    &-close {
        background-image: url('~assets/icons/ic_close.svg');
    }

    &-camera {
        background-image: url('~assets/icons/ic_camera.svg');
    }

    &-add {
        background-image: url('~assets/icons/ic_add.svg');
    }

    &-cameraWhite {
        background-image: url('~assets/icons/ic_camera_white.svg');
    }

    &-arrowCircleNextWhite {
        background-image: url('~assets/icons/ic_arrow_circle_next_white.svg');
    }

    &-arrowPrevWhite {
        background-image: url('~assets/icons/ic_arrow_prev_white.svg');
    }

    &-arrowCirclePrevWhite {
        background-image: url('~assets/icons/ic_arrow_circle_prev_white.svg');
    }
}
